<template>
  <div v-if="loaded" class="content-container">
    <a-button type="secondary" class="open-in-browser-button" @click="openInBrowser">Open in Browser</a-button>
    <iframe :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  data() {
    return {
      loaded: false,
      url: '',
    }
  },
  methods: {
    openInBrowser() {
      window.open(this.url, '_blank')
    },
  },
  mounted() {
    firebase.firestore().collection('reports').doc(this.$route.params.id).get()
      .then(doc => {
        this.url = doc.data().url
        this.loaded = true
      })
  },
}
</script>

<style>
  .content-container {
    position: relative;
  }
  .open-in-browser-button {
    position: absolute !important;
    bottom: 5px;
    right: 5px;
  }
  iframe {
    width: 100%;
    height: calc(100dvh - 90px);
  }
  .cui__utils__content {
    padding: 1em;
  }
</style>
